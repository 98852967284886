<template>
  <div>
    <breadcrumbs
      :breadcrumb-items="breadcrumbItems"
    />
    <UserToolbar
      v-if="account && Object.keys(account).length !== 0"
      :account="account"
    />
    <b-card>
      <CampaignToolbar
        v-if="Object.keys(campaign).length !== 0"
        :campaign="campaign"
        closing
      />
      <hr>
      <div
        role="alert"
        aria-live="polite"
        aria-atomic="true"
        class="alert alert-primary"
      >
        <div class="alert-body">
          <span>
            <strong>{{ $t('swal.attention') }}</strong> {{ $t('page.features.clicksourceshelptext') }}
          </span>
        </div>
      </div>
      <div class="table-filters">
        <div class="col-type">
          <b-form-radio-group
            id="type-report"
            v-model="typeStats"
            class="mb-1"
            button-variant="outline-primary"
            :options="optionsRadioLocal"
            buttons
            size="sm"
            name="radios-btn-default"
            @change="changeType"
          />
        </div>
        <div
          v-if="typeStats === 'custom'"
          class="col-picker"
        >
          <div class="d-flex align-items-center">
            <feather-icon
              icon="CalendarIcon"
              size="16"
            />
            <flat-pickr
              v-model="rangePicker"
              :config="{mode: 'range', dateFormat: 'Y-m-d'}"
              class="form-control flat-picker bg-transparent border-0 shadow-none"
              placeholder="YYYY-MM-DD"
              @on-change="getCampaignStatsByType"
            />
          </div>
        </div>
        <div>
          <b-button
            v-b-tooltip.hover.top="$t('toggleMap')"
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="outline-primary"
            size="sm"
            class="btn-icon"
            @click="toggleMap()"
          >
            <feather-icon
              icon="BarChart2Icon"
              size="16"
            />
          </b-button>
        </div>

      </div>
      <div
        v-if="loading"
        class="d-flex justify-content-center mb-1"
      >
        <b-spinner label="Loading..." />
      </div>
      <div v-else-if="rows.length">
        <stats-by-type
          v-if="showMap"
          :rows="rowsRevers"
          :type="typeStats"
          :range="rangePicker"
        />

        <b-table
          small
          :items="rows"
          :fields="filterFields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          responsive
          striped
          foot-clone
        >
          <template #head(datetime)>
            {{ $t('message.tableHeader.date') }}
          </template>

          <template #head(show)>
            {{ $t('message.tableHeader.impressions') }}
          </template>

          <template #head(click)>
            {{ $t('message.tableHeader.clicks') }}
          </template>

          <template #head(ecpm)>
            eCPM
          </template>

          <template #head(ctr)>
            CTR
          </template>

          <template #head(conversion)>
            {{ $t('stats.conversion') }}
          </template>

          <template #head(amount)>
            {{ $t('message.tableHeader.spent') }}
          </template>

          <template #cell(datetime)="data">
            {{ typeStats === 'day' ? getFormattedDateTime(new Date(data.value)) : getFormattedDate(new Date(data.value)) }}
          </template>

          <template #cell(amount)="data">
            {{ data.value ? currency(account.currency, data.value) : 0 }}
          </template>

          <template #cell(show)="data">
            {{ data.value ? data.value.toLocaleString('ru-RU') : 0 }}
          </template>

          <template #cell(click)="data">
            {{ data.value ? new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(data.value) : 0 }}
          </template>

          <template #cell(ctr)="data">
            {{ data.item.click ? new Intl.NumberFormat('en-GB', { style: 'decimal', maximumFractionDigits: 3 }).format(data.item.click / data.item.show * 100) + '%' : 0 }}
          </template>

          <template #cell(ecpm)="data">
            {{ new Intl.NumberFormat('en-GB', { style: 'decimal', maximumSignificantDigits: 3 }).format(data.item.amount / data.item.show * 1000) }}
          </template>

          <template #cell(conversion)="data">
            {{ data.value ? new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(data.value) : 0 }}
          </template>

          <template #cell(pixel)="data">
            {{ data.value ? new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(data.value) : 0 }}
          </template>

          <template #foot(datetime)="data">
            <strong :data-label="data.label">{{ $t('Records') }}: {{ rows.length }}</strong>
          </template>

          <template #foot(click)="data">
            <strong :data-label="data.label">{{ new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(totalFormat('click')) }}</strong>
          </template>

          <template #foot(show)="data">
            <strong :data-label="data.label">{{ totalFormat('show').toLocaleString('ru-RU') }}</strong>
          </template>
          <template #foot(conversion)="data">
            <strong :data-label="data.label">{{ new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(totalFormat('conversion')) }}</strong>
          </template>
          <template #foot(pixel)="data">
            <strong :data-label="data.label">{{ new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(totalFormat('pixel')) }}</strong>
          </template>
          <template #foot(amount)="data">
            <strong :data-label="data.label">{{ currency(account.currency, totalAmount) }}</strong>
          </template>
          <template #foot(ctr)="data">
            <strong :data-label="data.label">{{ totalFormat('click') ? new Intl.NumberFormat('en-GB', { style: 'decimal', maximumFractionDigits: 3 }).format(totalFormat('click') / totalFormat('show') * 100) : 0 }}%</strong>
          </template>
          <template #foot(ecpm)="data">
            <strong :data-label="data.label">{{ totalFormat('amount') ? new Intl.NumberFormat('en-GB', { style: 'decimal', maximumFractionDigits: 3 }).format(totalFormat('amount') / totalFormat('show' ) * 1000) : 0 }}</strong>
          </template>
        </b-table>
      </div>
      <div
        v-else
        class="card-nodata"
      >
        <div class="card-body">
          {{ $t('sites.nodata_period') }}
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import {
  BCard,
  BTable,
  BFormRadioGroup,
  BSpinner,
  VBTooltip,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Breadcrumbs from '@/views/ui/breadcrumbs/Breadcrumbs.vue'
import UserToolbar from '@/views/dashboard/users/UserToolbar.vue'
import CampaignToolbar from '@/views/dashboard/campaigns/CampaignToolbar.vue'
import { getUserData } from '@/auth/utils'
import flatPickr from 'vue-flatpickr-component'
import currencyFormatter from '@/utils/currency-formatter'
import getFormattedDate, { getFormattedDateTime } from '@/utils/date-formatter'
import StatsByType from './StatsType.vue'

export default {
  components: {
    Breadcrumbs,
    BCard,
    BTable,
    BFormRadioGroup,
    flatPickr,
    BSpinner,
    StatsByType,
    BButton,
    UserToolbar,
    CampaignToolbar,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      authUserData: getUserData(),
      showMap: false,
      loading: true,
      account: {},
      pageTitle: '',
      pageSubtitle: '',
      breadcrumbItems: [],
      typeStats: 'week',
      campaign: [],
      rows: [],
      banner: [],
      fields: [
        { key: 'datetime', label: 'Date' },
        { key: 'show', label: 'Impressions' },
        { key: 'click', label: 'Clicks' },
        { key: 'ctr', label: 'CTR' },
        { key: 'ecpm', label: 'eCpm' },
        { key: 'pixel', label: 'Pixel' },
        { key: 'conversion', label: 'Conversion' },
        { key: 'amount', label: 'Spent' },
      ],
      sortBy: 'datetime',
      sortDesc: true,
      sortDirection: 'asc',
      currency: currencyFormatter,
      getFormattedDate,
      getFormattedDateTime,
      optionsRadio: [
        { text: 'Today', key: 'today', value: 'day' },
        { text: '7 days', key: 'week', value: 'week' },
        { text: 'Month', key: 'month', value: 'month' },
        { text: 'Choose period', key: 'period', value: 'custom' },
      ],
      rangePicker: [getFormattedDate(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)), getFormattedDate(new Date())],
    }
  },
  computed: {
    rowsRevers() {
      const items = this.rows
      return items.reverse()
    },
    optionsRadioLocal() {
      return this.optionsRadio.map(o => ({
        value: o.value,
        text: this.$t(`stats.${o.key}`),
      }))
    },
    totalAmount() {
      let totalAmount = 0
      this.rows.forEach(i => {
        totalAmount += i.amount
      })
      return totalAmount
    },
    filterFields() {
      let { fields } = this
      if (!this.totalFormat('conversion')) {
        fields = fields.filter(f => f.key !== 'conversion')
      }
      if (!this.account.show_amount && !this.authUserData.role.includes('admin')) {
        fields = fields.filter(f => f.key !== 'amount').filter(f => f.key !== 'ecpm')
      }
      return fields
    },
  },
  created() {
    this.getUserData()
    this.fetchCampaignStatsByType()
  },
  methods: {
    totalFormat(field) {
      let total = 0
      this.rows.forEach(i => {
        if (i[field]) {
          total += i[field]
        }
      })
      return total
    },
    async getUserData() {
      const responseData = await useJwt.getUser(this.$route.params.userId)
      this.account = responseData.data.account || {}
      this.pageTitle = this.account.name
      this.pageSubtitle = this.account.email
      this.getCampaignData()
    },
    async getCampaignData() {
      const responseData = await useJwt.getCampaign(this.$route.params.id)
      this.campaign = responseData.data.campaign || {}
      this.setBreabcrumbs()
    },
    setBreabcrumbs() {
      this.breadcrumbItems = [
        {
          text: this.$t('Users'),
          to: '/users',
        },
        {
          text: this.pageTitle,
          to: `/users/${this.$route.params.userId}`,
        },
        {
          text: 'campaigns',
          to: `/users/${this.$route.params.userId}/campaigns`,
        },
        {
          text: this.campaign.title,
          to: `/users/${this.$route.params.userId}/campaigns/${this.$route.params.id}`,
        },
        {
          text: 'statistics',
          active: true,
        },
      ]
    },
    async fetchCampaignStatsByType() {
      let dateFrom
      let dateTo
      const urlSearchParams = new URLSearchParams(window.location.search)
      const urlParams = Object.fromEntries(urlSearchParams.entries())
      if (urlParams.type) {
        this.typeStats = urlParams.type
      }

      const selectedRange = this.rangePicker
      if (Array.isArray(selectedRange)) {
        [dateFrom, dateTo] = selectedRange
      } else {
        [dateFrom, , dateTo] = selectedRange.split(' ')
      }

      if (urlParams.from) {
        dateFrom = urlParams.from
      }

      if (urlParams.to) {
        dateTo = urlParams.to
      }

      if (dateTo !== undefined) {
        this.rangePicker = [dateFrom, dateTo]
        this.loading = true
        this.rows = []
        const params = {
          from: dateFrom,
          campaign_id: this.$route.params.id,
          to: dateTo,
          type: this.typeStats,
        }
        const responseData = await useJwt.getCampaignStatsByType(params)
        this.rows = responseData.data.campaignstats || []
      }
      this.loading = false
    },
    async getCampaignStatsByType() {
      let dateFrom
      let dateTo
      const urlSearchParams = new URLSearchParams(window.location.search)
      const urlParams = Object.fromEntries(urlSearchParams.entries())
      if (urlParams.type) {
        this.typeStats = urlParams.type
      }

      const selectedRange = this.rangePicker
      if (Array.isArray(selectedRange)) {
        [dateFrom, dateTo] = selectedRange
      } else {
        [dateFrom, , dateTo] = selectedRange.split(' ')
      }

      if (dateTo !== undefined) {
        this.$router.replace({
          name: 'dashboard-campaigns-stats',
          query: { type: this.typeStats, from: dateFrom, to: dateTo },
        }).catch(() => {})

        this.loading = true
        this.rows = []
        const params = {
          from: dateFrom,
          campaign_id: this.$route.params.id,
          to: dateTo,
          type: this.typeStats,
        }
        const responseData = await useJwt.getCampaignStatsByType(params)
        this.rows = responseData.data.campaignstats || []
      }
      this.loading = false
    },
    changeType() {
      if (this.typeStats === 'custom') {
        let dateFrom
        let dateTo
        const selectedRange = this.rangePicker
        if (Array.isArray(selectedRange)) {
          [dateFrom, dateTo] = selectedRange
        } else {
          [dateFrom, , dateTo] = selectedRange.split(' ')
        }
        this.$router.replace({
          name: 'dashboard-campaigns-stats',
          query: { type: this.typeStats, from: dateFrom, to: dateTo },
        })
      } else {
        this.$router.replace({
          name: 'dashboard-campaigns-stats',
          query: { type: this.typeStats },
        })
      }

      this.fetchCampaignStatsByType()
    },
    toggleMap() {
      this.showMap = !this.showMap
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style>
  .table-filters {
    display: flex;
    justify-content: space-between;
  }

  tfoot th {
    background-color: #fff!important;
  }

  .col-picker {
    min-width: 220px;
  }

  @media (max-width: 1400px) {
    .table-filters {
      flex-wrap: wrap;
      margin-bottom: 15px;
    }

    .col-type {
      width: 60%;
    }
  }

  @media (max-width: 992px) {
    .col-type {
      width: 70%;
    }
  }

  @media (max-width: 800px) {
    .col-type {
      width: 100%;
    }
  }
</style>
