<template>
  <div>
    <app-echart-stacked-area
      id="echart"
      :option-data="option"
    />
  </div>
</template>
<script>
import echarts from 'echarts/lib/echarts'
import getFormattedDate, { getFormattedDateTime } from '@/utils/date-formatter'
import AppEchartStackedArea from '@core/components/charts/echart/AppEchartStackedArea.vue'

export default {
  components: {
    AppEchartStackedArea,
  },
  props: {
    rows: {
      type: Array,
      default: null,
    },
    range: {
      type: [Array, String],
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      getFormattedDate,
      getFormattedDateTime,
      myChart: null,
      legends: [
        'CTR',
        'Clicks',
        'Shows',
        'Spent',
        'Users',
      ],
      option: {
        legend: {
          left: 0,
        },
        xAxisData: [],
        grid: {
          containLabel: false,
        },
        series: [],
      },
    }
  },
  created() {
    this.fetchCampaignDate()
    this.fetchCampaignStatistics()
  },
  mounted() {
    this.myChart = echarts.init(document.querySelector('#echart'))
    this.myChart.on('legendselectchanged', params => {
      this.selectGraph(params)
      this.unselectGrap(params)
    })
    this.setDefaults()
  },
  methods: {
    setDefaults() {
      const defaultLegend = {
        name: 'Ctr',
        selected: {
          Ctr: true,
          Clicks: false,
          Shows: false,
          Spent: false,
          Users: false,
        },
      }
      this.myChart.dispatchAction({
        type: 'legendSelect',
        name: 'Ctr',
      })
      this.selectGraph(defaultLegend)
      this.unselectGrap(defaultLegend)
    },
    fetchCampaignDate() {
      this.option.xAxisData = []
      if (this.type === 'month' || this.type === 'week' || this.type === 'custom') {
        this.rows.forEach(element => {
          this.option.xAxisData.push(getFormattedDate(new Date(element.datetime)))
        })
      } else if (this.type === 'day') {
        this.rows.forEach(element => {
          this.option.xAxisData.push(element.datetime.match(/\d\d:\d\d/))
        })
      }
    },
    fetchCampaignStatistics() {
      this.legends.forEach(legend => {
        let values = []
        values = this.rows.map(v => {
          let result = 0
          if (legend.toLowerCase() === 'ctr') {
            result = (v.click / v.show) * 100
          } else if (legend.toLowerCase() === 'clicks') {
            result = v.click
          } else if (legend.toLowerCase() === 'shows') {
            result = v.show
          } else if (legend.toLowerCase() === 'spent') {
            result = v.amount
          }
          return result
        }) || []

        this.option.series.push({
          name: legend,
          type: 'line',
          stack: 'Total',
          areaStyle: {},
          showSymbol: false,
          lineStyle: {
            width: 0,
          },
          data: values,
        })
      })
    },
    selectGraph(params) {
      this.myChart.dispatchAction({
        type: 'legendSelect',
        name: params.name,
      })
    },
    unselectGrap(params) {
      Object.keys(params.selected).forEach(param => {
        if (param !== params.name) {
          this.myChart.dispatchAction({
            type: 'legendUnSelect',
            name: param,
          })
        }
      })
    },
    findDayDifference(date1, date2) {
      return Math.floor((Math.abs(date2 - date1)) / (1000 * 60 * 60 * 24))
    },
  },
}
</script>
<style scoped>
.echarts {
  width: 100% !important
}
</style>
